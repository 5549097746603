import React from 'react'

import {MainPage} from './pages/Main'

import './App.scss'

function App() {
  // TODO: ReactRouter
  return (
    <div className="app">
      <MainPage />
    </div>
  );
}

export default App;
