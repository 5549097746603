import React from 'react'

import '../Panels.scss'

export const InfoPanel = () => {
  return (
    <div className="panel">
      <div className="panel-headline">
        Welcome to the Conker&apos;s Bad Fur Day decompilation website!
      </div>
      <div className="panel-text">
      </div>
    </div>
  )
}
